import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Button, Card } from 'react-bootstrap';
import { TOGCharacter } from '../../../modules/tog/common/components/tog-character';

const TOGGuidesMetaTeams: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Meta teams</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_meta.jpg"
            alt="Early team progression"
          />
        </div>
        <div className="page-details">
          <h1>Meta teams (Story)</h1>
          <h2>
            The current meta teams in the Story content in Tower of God: New
            World!
          </h2>
          <p>
            Last updated: <strong>18/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Conowen's Sheet" />
        <p>For the most up to date information, check this sheet:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/d/1paOxiwoKOzzR0E5W_r1SYLVuliJomnz8BkNQt8vNhQU/edit?gid=112766071#gid=112766071"
          target="_blank"
          rel="noreferrer"
        >
          Conowen's ToG Sheet
        </Button>
        <SectionHeader title="Story meta teams (per Color)" />
        <p>
          While initially, to progress though the Tower (so main story), you
          will only need a single team, this will change rather quickly. Chapter
          16 is the moment where you will need 2 teams, Chapter 21 where you
          will need 3 teams and Chapter 31 where you will need 4 teams to tackle
          the Elite Stages. Below you will find the 4 most popular teams used by
          the players who reached Chapter 40+ - you can safely use them in the
          earlier chapters or slowly build your teams to match them.
        </p>
        <p>
          Also, we will focus on mono element teams as you usually run those
          unless you need to add a specific character to counter someone.
        </p>
        <h5>Team #1 (Yellow)</h5>
        <p>
          The Yellow team is the first one everyone should build as Yihwa is
          simply from another world and makes the early game progression
          smoother.
        </p>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="flame-user-yihwa-yeon"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Healer</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="fast-ship-evan"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="black-march-bam"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Stall tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="hatz-donghae"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="pink-knit-sweater-goseng-yeo"
                enablePopover
                showIcon
              />
            </Card>
          </div>
        </div>
        <p>If you don't want want to use Goseng, you can replace her with:</p>
        <ul>
          <li>
            <strong>Rak [Compressed]</strong> for more damage,
          </li>
          <li>
            <strong>Khun Edahn [Data]</strong> to snipe backline,
          </li>
          <li>
            <strong>Zahard [Data] and Khun Mascheny [Data]</strong> can be used
            instead of Hatz Donghae early on when you still don't need them in
            their own teams.
          </li>
        </ul>
        <h5>Team #2 (Purple)</h5>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="demonic-fragment-hoaqin"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="sub-dps">Sub-DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="assassin-khun-kiseia"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="ha-yura-idol"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Stall tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="steel-armored-karaka"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="flex">Flex</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="strategist-khun-aguero"
                enablePopover
                showIcon
              />
            </Card>
          </div>
        </div>
        <p>Replacements:</p>
        <ul>
          <li>
            <strong>Vivi [Daughter Slayer]</strong> can be used instead of
            Hoaqin or Kiseia as your damage dealer of choice,
          </li>
          <li>
            <strong>Varagarv [Mad Dog]</strong> can be used instead of Karaka in
            the tank spot.
          </li>
        </ul>
        <h5>Team #3 (Green)</h5>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="child-endorsi"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="sub-dps">Sub DPS + Stall tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="data-zahard"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="proud-waves-lozeal"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="sub-dps">Sub-DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="blooming-flower-miseng-yeo"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="flex">Flex</h5>
            <div className="flex-placeholder">Flex</div>
          </div>
        </div>
        <p>In the flex spot you can use:</p>
        <ul>
          <li>
            <strong>Ren [Punisher] </strong> for additional tanking / damage,
          </li>
          <li>
            <strong>Rachel [Phoenix Incarnate]</strong> for more damage (she is
            collab limited character!),
          </li>
          <li>
            <strong>Yeonhee [Doomsday Herald]</strong> for CC / Support (she is
            collab limited character!).
          </li>
        </ul>
        <h5>Team #4 (Blue)</h5>
        <p>
          How you will build your Blue team heavily depends if you played during
          the 7 Deadly Sins collab as characters from it form the currently best
          Blue team.
        </p>
        <p>
          <strong>7DS Collab team</strong>
        </p>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="cursed-shackles-meliodas"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="the-one-escanor"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="center-director-blanc"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="sub-dps">Sub-DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="eternal-rebirth-elizabeth"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="flex">Flex</h5>
            <div className="flex-placeholder">Flex</div>
          </div>
        </div>
        <p>In the flex spot you can use:</p>
        <ul>
          <li>
            <strong>Khun Ran [Lightning Pill]</strong> for more damage,
          </li>
          <li>
            <strong>Urek Mazino [Flight] </strong> for more damage / tanking,
          </li>
          <li>
            <strong>Lero Ro [Tactician]</strong> for additional support,
          </li>
          <li>
            <strong>Blarode [One-eyed Wolf]</strong> to pull the backline to
            yourself.
          </li>
        </ul>
        <p>
          <strong>Normal team</strong>
        </p>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="kaiser-ruler"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="main-dps">Sub DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="one-eyed-wolf-blarode"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="data-khun-mascheny"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="center-director-blanc"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="flex">Flex</h5>
            <div className="flex-placeholder">Flex</div>
          </div>
        </div>
        <p>In the flex spot you can use:</p>
        <ul>
          <li>
            <strong>Lero Ro [Tactician]</strong> for additional support,
          </li>
          <li>Any other Blue unit that provides some utility.</li>
        </ul>
        <h5>Team #5 (Red)</h5>
        <div className="custom-team-showcase tog">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="thorn-viole"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="destined-path-hwaryun"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Support</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="maniacal-xia-xia"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="sub-dps">Sub-DPS</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="doctor-michael"
                enablePopover
                showIcon
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Tank</h5>
            <Card className="avatar-card">
              <TOGCharacter
                mode="icon"
                slug="rak-awakened"
                enablePopover
                showIcon
              />
            </Card>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesMetaTeams;

export const Head: React.FC = () => (
  <Seo
    title="Meta teams (Story) | Tower of God: New World | Prydwen Institute"
    description="The current meta teams in the Story and Bossing content in Tower of God: New World!"
    game="tog"
  />
);
